<template>
    <section class="callPhone">
        <div class="title">免费体验短信服务</div>
        <div class="desc">已为1,000,000+位用户提供免费体验服务</div>
        <div class="box">
            <div class="tel_box">
            <el-input class="input" v-model="input" placeholder="请输入中国大陆手机号码"></el-input>
            <a class="c_btn primary " data-agl-cvt="5" @click="smsSend">免费体验</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
data() {
    return {
    input:''
    };
},
components: {},

watch: {
    $route: {
    handler: function(val, oldVal){

    },
    // 深度观察监听
    deep: true,
    immediate:true
    }
},
mounted() {

},
methods: {
    toPath(path){
    if(!path){
        return
    }
    if(path.includes(this.OUT_URL)){
        window.open(path, '_blank');
    }else{
        
    }
    },
    smsSend(){
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!reg_tel.test(this.input)) {
            alert('手机号码格式有误，请重新输入!')
            return
        }
        window.open(this.OUT_URL)
    }
},
};
</script>

<style lang='less' scoped>
.callPhone{
background-color: #fff;
box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
height: 287px;
padding: 42px 0 44px;
text-align:center;
position: relative;
.title{
    font-size:36px;
    color:#333;
    font-weight:bold;
}
.desc{
    font-size:20px;
    color:#333;
    margin:14px 0 44px;
}
.box{
    margin: 0px auto;
    display: inline-block;
}
.tel_box{
    display:flex;
    align-items:center;
    .input{
        background: rgba(255, 255, 255, 0.4);
        width: 435px;
        height: 65px;
        border: 1px solid #1776FF;
        font-size: 20px;
        // padding-left: 40px;
       /deep/ .el-input__inner{
            margin-top: 12px !important;
            border: none !important;
        }
    }
    .c_btn{
        width:230px;
        height:65px;
        line-height:65px;
        margin-left:40px;
    }
}
}
</style>
  
<template>
    <section class="custom">
        <p class="main_title">优质贴心服务</p>
        <p class="main_desc">一对一的服务，让科敦云信息赢得了众多用户的认可</p>
        <div class="log_box">
            <template v-for="(item,index) in log_list">
                <el-image class="log_box_img" v-if="OSS_STATE"   :src="`${OSS_STATE}/assets/logImages/${item.icon}`"  lazy></el-image>
                <img class="log_box_img" v-else  :src="require(`../../assets/logImages/${item.icon}`)" alt="">
            </template>
        </div>
    </section>
</template>

<script>
export default {
    name:'custom',
    data() {
        return {
            log_list:[
                { icon:'logo_1.png' },
                { icon:'logo_2.png' },
                { icon:'logo_3.png' },
                { icon:'logo_4.png' },
                { icon:'logo_5.png' },
                { icon:'logo_6.png' },
                { icon:'logo_7.png' },
                { icon:'logo_8.png' },
                { icon:'logo_9.png' },
                { icon:'logo_10.png' },
                { icon:'logo_11.png' },
                { icon:'logo_12.png' },
                { icon:'logo_13.png' },
                { icon:'logo_14.png' },
                { icon:'logo_15.png' },
                { icon:'logo_16.png' },
            ]
        };
    },
    props: {
        list: {
            type: Array
        },
        suspension:{
            type: Array
        }
    },
    components: {},

    watch: {
        $route: {
        handler: function(val, oldVal){

        },
        // 深度观察监听
        deep: true,
        immediate:true
        }
    },
    mounted() {
        // console.log(this.list)
    },
    methods: {
        toPath(path){
        if(!path){
            return
        }
        if(path.includes(this.OUT_URL)){
            window.open(path, '_blank');
        }else{
            
        }
        }
    },
};
</script>

<style lang='less' scoped>

.custom{
    
    margin-bottom: 60px;
    .log_box{
        width: 1096px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .log_box_img{
            width: 254px;
            height: 94px;
            margin: 0 20px 20px 0;
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.04);
        }
    }
}
</style>
  
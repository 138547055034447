import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Home/Home'
// import introduction from '../views/newEdition/introduction/index.vue'
// import contact from '../views/newEdition/contact/index.vue'
// import honor from '../views/newEdition/honor/index.vue'
// import history from '../views/newEdition/history/index.vue'
// import doctor from '../views/newEdition/product/doctor.vue'
// import drugstore from '../views/newEdition/product/drugstore.vue'
// import patient from '../views/newEdition/product/patient.vue'
// import news from '../views/newEdition/news/index.vue'
// import newsDetail from '../views/newEdition/news/detail.vue'
// import text from '../views/newEdition/product/text.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {title: '科敦云-群发短信-短信验证码-营销短信-短信接口平台'},
  },
  {
      name: 'solution',
      path: '/solution',
      component: () => import('../views/solution/solution.vue'),
      meta: {
          title: '科敦云',
      },
  },
  {
      name: 'Tradekefang',
      path: '/Tradekefang',
      component: () => import('../views/Tradekefang/Tradekefang.vue'),
      meta: {
          title: '科敦云-国内通知短信',
      },
  },
  {
      name: 'Marketkefang',
      path: '/Marketkefang',
      component: () => import('../views/Marketkefang/Marketkefang.vue'),
      meta: {
          title: '科敦云-国内营销短信',
      },
  },
  {
      name: 'Vokefang',
      path: '/Vokefang',
      component: () => import('../views/Vokefang/Vokefang.vue'),
      meta: {
          title: '科敦云-语音服务',
      },
  },
  {
      name: 'Eykefang',
      path: '/Eykefang',
      component: () => import('../views/Eykefang/Eykefang.vue'),
      meta: {
          title: '科敦云-空号检测',
      },
  },
  {
      name: 'Shortkefang',
      path: '/Shortkefang',
      component: () => import('../views/Shortkefang/Shortkefang.vue'),
      meta: {
          title: '科敦云-短链接',
      },
  },
  {
      name: 'Pekefang',
      path: '/Pekefang',
      component: () => import('../views/Pekefang/Pekefang.vue'),
      meta: {
          title: '科敦云-产品价格方案',
      },
  },
  {
      name: 'Sakefang',
      path: '/Sakefang',
      component: () => import('../views/Sakefang/Sakefang.vue'),
      meta: {
          title: '科敦云-信息安全',
      },
  },
  {
      name: 'Relation',
      path: '/Relation',
      component: () => import('../views/Relation/Relation.vue'),
      meta: {
          title: '科敦云-联系我们',
      },
  },
  {
      name: 'aboutUs',
      path: '/aboutUs',
      component: () => import('../views/aboutUs/aboutUs.vue'),
      meta: {
          title: '科敦云-关于科敦',
      },
  },
  {
    name: 'kefangAPI',
    path: '/kefangAPI',
    component: () => import('../views/kefangAPI/kefangAPI.vue'),
    meta: {
        title: '科敦云-文档中心API',
    },
},

  
  
  // {
  //   path: '/introduction',
  //   name: 'introduction',
  //   component: introduction
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: contact
  // },
  // {
  //   path: '/honor',
  //   name: 'honor',
  //   component: honor
  // },
  // {
  //   path: '/history',
  //   name: 'history',
  //   component: history
  // },
  // {
  //   path: '/doctor',
  //   name: 'doctor',
  //   component: doctor
  // },
  // {
  //   path: '/drugstore',
  //   name: 'drugstore',
  //   component: drugstore
  // },
  // {
  //   path: '/patient',
  //   name: 'patient',
  //   component: patient
  // },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: news
  // },
  // {
  //   path: '/newsDetail',
  //   name: 'newsDetail',
  //   component: newsDetail
  // },
  // {
  //   path: '/text',
  //   name: 'text',
  //   component: text
  // },

  
]

const router = new VueRouter({
    //正式
  mode: 'history',
//     测试
    // mode: 'history',
    // base: 'pc',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }


})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vant from 'vant';
// import 'vant/lib/index.css';
// import './plugins/vant.js'
import './plugins/element.js'
import SlideVerify from 'vue-monoplasty-slide-verify'
import dragVerify from 'vue-drag-verify'
import 'font-awesome/css/font-awesome.min.css'

Vue.config.productionTip = false
Vue.prototype.OSS_STATE = process.env.VUE_APP_OSS_URL ? true : false;
Vue.prototype.OUT_URL = process.env.VUE_APP_OUT_URL
// Vue.use(Vant);
Vue.use(SlideVerify)
Vue.use(dragVerify)

router.afterEach((to, from)=> {
  document.title = '科敦云信息平台-' + to.meta.title
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

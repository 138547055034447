import request from '@/network/requestNew'

// 登录方法
export function login(username, password, captcha, checkKey) {
  console.log(checkKey)
  const data = {
    username,
    password,
    captcha,
    checkKey
  }
  return request({
    url: '/sys/system/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/api/sms/api/createUser',
    method: 'post',
    data: data
  })
}

export function sendcode(data) {
  return request({
    url: '/auth/auth/send/code',
    method: 'post',
    data: data
  })
}

export function verifycode(data) {
  return request({
    url: '/auth/auth/verify/code',
    method: 'post',
    data: data
  })
}

export const REG_PHONE = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
export const REG_PSSWORD = /^[a-zA-Z\d]{6,16}$/





